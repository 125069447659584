import React, { useState } from "react";
import { Container, Form, Row, Col, Dropdown, Button } from "react-bootstrap";

function Reservation() {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [email, setEmail] = useState("");
  const [dropDown, setDropDown] = useState("Auswählen");
  const [persons, setPersons] = useState(0);
  const [message, setMessage] = useState("");

  const submit = (e) => {
    e.preventDefault();
    console.log(firstname, lastname, date, time, email, dropDown, message);
  };

  return (
    <Container lg={2} className="mb-3">
      <Row>
        <Col>
          <h1>Reservation</h1>
        </Col>
      </Row>
      <Row>
        <Col xl={6}>
          <Form>
            <Form.Group className="d-flex align-items-start flex-column">
              <h5>Vorname</h5>
              <Form.Control
                placeholder="Vorname"
                onChange={(e) => setFirstname(e.target.value)}
                value={firstname}
                type="text"
              ></Form.Control>
            </Form.Group>

            <Form.Group className="d-flex align-items-start flex-column">
              <h5>Nachname</h5>
              <Form.Control
                placeholder="Nachname"
                onChange={(e) => setLastname(e.target.value)}
                value={lastname}
                type="text"
              ></Form.Control>
            </Form.Group>

            <Form.Group className="d-flex align-items-start flex-column">
              <h5>Datum</h5>
              <Form.Control
                type="date"
                onChange={(e) => setDate(e.target.value)}
                value={date}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="d-flex align-items-start flex-column">
              <h5>Zeit</h5>
              <Form.Control
                type="time"
                onChange={(e) => setTime(e.target.value)}
                value={time}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="d-flex align-items-start flex-column">
              <h5>Email</h5>
              <Form.Control
                placeholder="Email"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                vlaue={email}
              ></Form.Control>
            </Form.Group>

            <Form.Group className="d-flex align-items-start flex-column">
              <h5>Mahlzeit</h5>
              <Dropdown>
                <Dropdown.Toggle>{dropDown}</Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => setDropDown("Frühstück / Brunch")}
                  >
                    Frühstück / Brunch
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setDropDown("Mittagessen")}>
                    Mittagessen
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => setDropDown("Abendessen")}>
                    Abendessen
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>

            <Form.Group className="d-flex align-items-start flex-column">
              <h5>Anzahl Personen</h5>
              <p className="d-flex">
                <Button
                  onClick={() =>
                    setPersons(persons > 0 ? persons - 1 : persons)
                  }
                >
                  -
                </Button>
                <h4 className="m-3">{persons}</h4>
                <Button onClick={() => setPersons(persons + 1)}>+</Button>
              </p>
            </Form.Group>

            <Form.Group className="d-flex align-items-start flex-column">
              <h5>Mitteilung</h5>
              <textarea
                rows="3"
                className="form-control"
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              ></textarea>
            </Form.Group>

            <Button type="submit" onClick={submit}>
              Abschicken
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default Reservation;
