import React, { useState } from "react";
import { Button, Alert, Row, Col, Container } from "react-bootstrap/";

function Lists() {
  const data = [
    { color: "red", red: 255, green: 0, blue: 0 },
    { color: "green", red: 0, green: 255, blue: 0 },
    { color: "blue", red: 0, green: 0, blue: 255 },
  ];
  const [colors, setColors] = useState(data);

  const [textValue, setTextValue] = useState("");

  var rgbToHex = function (rgb) {
    var hex = Number(rgb).toString(16);
    if (hex.length < 2) {
      hex = "0" + hex;
    }
    return hex;
  };

  var fullColorHex = function (r, g, b) {
    var red = rgbToHex(r);
    var green = rgbToHex(g);
    var blue = rgbToHex(b);
    return red + green + blue;
  };

  function randomColor(e) {
    e.preventDefault();
    let r = Math.floor(Math.random() * 256);
    let g = Math.floor(Math.random() * 256);
    let b = Math.floor(Math.random() * 256);
    setColors([...colors, { color: textValue, red: r, green: g, blue: b }]);
  }

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <h1>Lists</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <ul>
              {colors.map((color, idx) => (
                <li
                  key={"color-" + idx}
                  style={{
                    padding: "10px",
                    backgroundColor:
                      "#" + fullColorHex(color.red, color.green, color.blue),
                  }}
                >
                  {color.color +
                    " (" +
                    color.red +
                    "," +
                    color.green +
                    "," +
                    color.blue +
                    ")"}{" "}
                  {" #"}
                  {fullColorHex(color.red, color.green, color.blue)}
                </li>
              ))}
            </ul>
          </Col>
        </Row>
          <Row>
            <Col>
              <form onSubmit={randomColor}>
                <input
                  type="text"
                  value={textValue}
                  onChange={(e) => setTextValue(e.target.value)}
                />
                <Button onClick={randomColor}>Random Color</Button>
              </form>
            </Col>
          </Row>
        <Row>
          <Col>
            <Alert variant="warning">
              This is a warning alert—check it out!
            </Alert>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Lists;
