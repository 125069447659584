import React from "react";
import { ProgressBar, Row, Container, Col } from "react-bootstrap/";

function Progress() {
  
    const value = 70;

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <h1>Progress</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Standard</h2>
            <ProgressBar now={60} />
            <h2>Mit Label</h2>
            <ProgressBar now={value} label={`${value} %`} />
            <h2>Verschiedene Farben bzw. Typen</h2>
            <ProgressBar variant="success" now={40} />
            <ProgressBar variant="info" now={20} />
            <h2>Mit Streifen</h2>
            <ProgressBar striped variant="warning" now={60} />
            <ProgressBar striped variant="danger" now={80} />
            <h2>Animiert</h2>
            <ProgressBar animated now={45} />
            <h2>Stacked</h2>
            <ProgressBar>
              <ProgressBar striped variant="success" now={35} key={1} />
              <ProgressBar variant="warning" now={20} key={2} />
              <ProgressBar striped variant="danger" now={10} key={3} />
            </ProgressBar>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default Progress;
