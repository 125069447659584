import React, { useState, useEffect } from "react";
import { Container, Table } from "react-bootstrap";

function SBBDashboard() {
  const [connections, setConnections] = useState([]);

  const getData = () => {
    fetch("http://transport.opendata.ch/v1/stationboard?station=Winterthur")
      .then((response) => response.json())
      .then((data) => {
        setConnections(data.stationboard);
      });
  };

  const beautifyDateTime = (e) => {
    if (e.toString().length < 2) {
      return `0${e}`;
    } else {
      return e;
    }
  };

  const convertTime = (e) => {
    let date = new Date(e);
    return (
      beautifyDateTime(date.getHours()) +
      ":" +
      beautifyDateTime(date.getMinutes())
    );
  };

  useEffect(() => getData());

  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <th> </th>
            <th> </th>
            <th>Nach</th>
            <th>Gleis</th>
            <th>Hinweis</th>
          </tr>
        </thead>
        <tbody>
          {connections &&
            connections.map((connection) => (
              <tr>
                <td>
                  {connection.category}
                  {connection.number}
                </td>
                <td>{convertTime(connection.stop.departure)}</td>
                <td>
                  {connection.passList.slice(0, 5).map((passelement) => (
                    <>{passelement.station.name} </>
                  ))}
                  <b>{connection.to}</b>
                </td>
                <td>{connection.stop.platform}</td>
                <td></td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
}

export default SBBDashboard;
