import React, { useState } from "react";
import { Row, Container, Col } from "react-bootstrap/";

function CardsAPI() {
  const [card, setCards] = useState([]);
  const [recentCards, setRecentCards] = useState([]);
  //const [deck, setDeck] = useState([]);

  // function newDeck() {
  //   fetch(
  //     "https://deckofcardsapi.com/api/deck/new/shuffle/?deck_count=52"
  //   ).then((result) => result.json());
  //   //.then(data => )
  // }

  const newCard = () => {
    fetch("https://deckofcardsapi.com/api/deck/new/draw/?count=1")
      .then((result) => result.json())
      .then((data) => {
        setCards(data.cards);
        setRecentCards([...recentCards, data.cards[0]]);
      });
  };

  const orderByValue = (value) =>
    [
      "ACE",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "JACK",
      "QUEEN",
      "KING",
    ].indexOf(value);

  // const orderBySuit = (value) =>
  //   ["HEARTS", "CLUBS", "DIAMONDS", "SPADES"].indexOf(value);

  return (
    <Container>
      <div class="recentCards">
        <h2 style={{ width: "100%" }}>Orderd by value</h2>
        <br />
        {recentCards
          .sort((c1, c2) => orderByValue(c1.value) - orderByValue(c2.value))
          .map((card) => (
            <img src={card.image} alt="Card" style={{ width: "75px" }} />
          ))}
      </div>
      <div class="recentCardsBySuits">
        <h2 style={{ width: "100%" }}>Orderd by suit</h2>

        <div style={{ display: "block" }}>
          {["HEARTS", "CLUBS", "DIAMONDS", "SPADES"].map((group) => (
            <>
              {recentCards
                .filter((c) => c.suit === group)
                .sort(
                  (c1, c2) => orderByValue(c1.value) - orderByValue(c2.value)
                )
                .map((card) => (
                  <img src={card.image} alt="Card" style={{ width: "75px" }} />
                ))}
              <br />
            </>
          ))}
        </div>
      </div>
      <Row>
        <Col>
          <h1>Cards</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <button onClick={newCard} class="btn btn-primary">
            {" "}
            New Card{" "}
          </button>
          <br />
          <br />
          {card.map((card) => (
            <img src={card.image} alt="Card" />
          ))}
        </Col>
      </Row>
    </Container>
  );
}
export default CardsAPI;
