import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

function SBBAPI() {
  const [startbahnhof, setStartbahnhof] = useState("Winterthur");
  const [zielbahnhof, setZielbahnhof] = useState("Zürich");
  const [connections, setConnections] = useState([]);

  const search = (e) => {
    e.preventDefault();

    fetch(
      "http://transport.opendata.ch/v1/connections?from=" +
        startbahnhof +
        "&to=" +
        zielbahnhof
    )
      .then((response) => response.json())
      .then((json) => {
        setConnections(json.connections);
      });
  };

  const beautifyDateTime = (e) => {
    if (e.toString().length < 2) {
      return `0${e}`;
    } else {
      return e;
    }
  };

  const convertTime = (e) => {
    let date = new Date(e);
    return (
      beautifyDateTime(date.getDay()) +
      "." +
      beautifyDateTime(date.getMonth()) +
      " " +
      beautifyDateTime(date.getHours()) +
      ":" +
      beautifyDateTime(date.getMinutes()) +
      " Uhr"
    );
  };

  return (
    <Container className="p-3">
      <Row>
        <Col className="d-flex align-items-center flex-column">
          <form action="/" className="w-50">
            <p>Gib einen Startbahnhof ein:</p>
            <input
              className="form-control"
              type="text"
              value={startbahnhof}
              onChange={(e) => setStartbahnhof(e.target.value)}
              placeholder="Startbahnhof"
            />
            <p>Gib einen Zielbahnhof ein:</p>
            <input
              className="form-control"
              type="text"
              value={zielbahnhof}
              onChange={(e) => setZielbahnhof(e.target.value)}
              placeholder="Zielbahnhof"
            />
            <br />
            <Button type="submit" onClick={search}>
              Suchen
            </Button>
          </form>
        </Col>
      </Row>
      <Row>
        <Col className="d-flex align-items-center flex-column">
          {connections.map((connection) => (
            <div className="bg-secondary text-light w-50 mt-5 rounded">
              <p>Von: {connection.from.location.name}</p>
              <p>Nach: {connection.to.location.name}</p>
              <p>Auf Gleis: {connection.from.platform}</p>
              <br />
              {connection.from.departure && (
                <p>Abfahrt: {convertTime(connection.from.departure)}</p>
              )}
              {connection.to.arrival && (
                <p>Ankunft: {convertTime(connection.to.arrival)}</p>
              )}
            </div>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

export default SBBAPI;
