import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { HeartFill, Heart, StarFill, Star } from "react-bootstrap-icons";

function ExampleComponentsAndProps() {
  const [counter, setCounter] = useState(0);

  return (
    <Container>
      <Row>
        <Col>
          <h1>Komponente 1</h1>
          <Function2
            data={"Hiii from 1"}
            data2={883}
            onMinus={() => setCounter(counter - 1)}
            onPlus={() => setCounter(counter + 1)}
            counter={counter}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Like onChange={(e) => console.log(e)} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Stars2 amount={5} onChangeStars={(e) => console.log(e)} />
        </Col>
      </Row>
    </Container>
  );
}

function Function2(props) {
  return (
    <>
      <p>Hiiii from 2</p>
      <br />
      {props.data}, {props.data2}
      <br />
      <Button onClick={() => props.onMinus()}>Minus</Button>
      <p>{props.counter}</p>
      <Button onClick={() => props.onPlus()}>Plus</Button>
    </>
  );
}

function Like(props) {
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    props.onChange(liked);
  }, [liked, props]);

  const like = () => {
    setLiked(!liked);
  };

  return (
    <>
      {liked ? (
        <HeartFill onClick={like} style={{ cursor: "pointer" }} />
      ) : (
        <Heart onClick={like} style={{ cursor: "pointer" }} />
      )}
    </>
  );
}

/*function Stars(props) {
  const [stars, setStars] = useState([false, false, false, false, false]);
  const starsPlus = (idx) => {
    let newStars = stars.slice();
    newStars.map((test, newIdx) => {
      if (newIdx <= idx) {
        newStars[newIdx] = true;
      }
    });
    setStars(newStars);
  };

  const starsMinus = (idx) => {
    let newStars = stars.slice();
    newStars.map((test, newIdx) => {
      if (newIdx > idx) {
        newStars[newIdx] = false;
        return;
      }
    });
    setStars(newStars);
  };

  return (
    <>
      {[1, 2, 3, 4, 5].map((star, idx) =>
        star ? (
          <StarFill onClick={() => starsMinus(idx)} />
        ) : (
          <Star onClick={() => starsPlus(idx)} />
        )
      )}
    </>
  );
}*/

function Stars2({ amount, onChangeStars }) {
  const [click, setClick] = useState(-1);

  const handleClick = (i) => setClick(click === i ? -1 : i);

  useEffect(() => {
    onChangeStars(click + 1);
  }, [click, onChangeStars]);

  return (
    <>
      {new Array(amount)
        .fill()
        .map((star, i) =>
          i <= click ? (
            <StarFill
              key={i}
              onClick={() => handleClick(i)}
              style={{ color: "gold" }}
            />
          ) : (
            <Star
              key={i}
              onClick={() => handleClick(i)}
              style={{ color: "gold" }}
            />
          )
        )}
    </>
  );
}

export default ExampleComponentsAndProps;
