import Radio from "./Radio";
import Lists from "./Lists";
import Home from "./Home";
import Progress from "./Progress";
import CardsAPI from "./CardsAPI";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Datamuse from "./Datamuse";
import SBBAPI from "./SBBAPI";
import SBBDashboard from "./SBBDashboard";
import Reservation from "./Reservation";
import ExampleComponentsAndProps from './ExampleComponentsAndProps'
import { Dropdown } from "react-bootstrap";
import "./App.css";
import Shoppinglist from "./Shoppinglist";

function App() {
  return (
    <div className="App">
      <Router>
        <div>
          <ul className="navigation" >
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/radio">Radio</Link>
            </li>
            <li>
              <Link to="/lists">Lists</Link>
            </li>
            <li>
              <Link to="/progressbar">Progressbar</Link>
            </li>
            <li>
              <Link to="/datamuse">Datamuse</Link>
            </li>
            <li>
              <Link to="/cardgame">Cardgame</Link>
            </li>
            <li>
              <Link to="/fahrplan">Fahrplan</Link>
            </li>
            <li>
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li>
              <Link to="/reservation">Reservation</Link>
            </li>
            <li>
              <Link to="/props">Props</Link>
            </li>
            <li>
              <Link to="/shoppinglist">Shoppinglist</Link>
            </li>
          </ul>

          <Dropdown className="navigationMobile mt-3">
            <Dropdown.Toggle>Select Page</Dropdown.Toggle>

            <Dropdown.Menu>
            <Dropdown.Item>
              <Link to="/">Home</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/radio">Radio</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/lists">Lists</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/progressbar">Progressbar</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/datamuse">Datamuse</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/cardgame">Cardgame</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/fahrplan">Fahrplan</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/dashboard">Dashboard</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/reservation">Reservation</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/props">Props</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/shoppinglist">Shoppinglist</Link>
            </Dropdown.Item>
            <Dropdown.Item>
              <Link to="/props">Props</Link>
            </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <hr />

          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/radio">
              <Radio />
            </Route>
            <Route path="/lists">
              <Lists />
            </Route>
            <Route path="/progressbar">
              <Progress />
            </Route>
            <Route path="/datamuse">
              <Datamuse />
            </Route>
            <Route path="/cardgame">
              <CardsAPI />
            </Route>
            <Route path="/fahrplan">
              <SBBAPI />
            </Route>
            <Route path="/dashboard">
              <SBBDashboard />
            </Route>
            <Route path="/reservation">
              <Reservation />
            </Route>
            <Route path="/props">
              <ExampleComponentsAndProps/>
            </Route>
            <Route path="/shoppinglist">
              <Shoppinglist />
            </Route>
          </Switch>
        </div>
      </Router>
      </div>
  );
}
export default App;
