import React, { useState } from "react";
import { Row, Container, Col } from "react-bootstrap/";

function Datamuse() {
  const [inputWord, setInputWord] = useState("");
  const [words, setWords] = useState([]);

  function changeHandler(e) {
      setInputWord(e.target.value);
  }

  const btnHandler = () => {
      fetch('https://api.datamuse.com/words?rel_rhy='+ inputWord)
      .then(response => response.json())
      .then(data => setWords(data))
  };

  return (
    <Container>
        <Row>
            <Col>
                <h1>Datamuse rhymes</h1>
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <input type="text" value={inputWord} onChange={e => changeHandler(e)} className="form-control"/>
                <button onClick={btnHandler} className="btn btn-secondary">Reime suchen</button>
                <br/>
                <h2>List</h2>
                <ul>
                    {words.map(
                        (rhyme) => <li>{rhyme.word}</li> 
                    )}
                </ul>
            </Col>
        </Row>
    </Container>
  );
}
export default Datamuse;
