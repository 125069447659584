import React, { useState } from 'react';

function Radio() {

    const [value, setValue] = useState();


    // function onChangeValue(e) {
    //     setValue(e);
    //     console.log(value);
    // }

    return (
        <div>
            <div onChange={e => setValue(e.target.value)}>
                <input type="radio" name="gen" value="frau" /> Frau
                <input type="radio" name="gen" value="mann" /> Mann
                <input type="radio" name="gen" value="other" /> Ohter
            </div>
            {value}
        </div>
    )
}
export default Radio;