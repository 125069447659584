import React, { useState } from "react";
import {
    Container,
    Row,
    Col,
  Form,
  FormControl,
  Accordion,
  Button,
  Card
} from "react-bootstrap";
import useLocalStorage from './useLocalStorage'

function Shoppinglist() {
    const [list, setList] = useLocalStorage("Shoppinglist", []);
    const [input, setInput] = useState("");
    
    const handleChange = (id, check) => {
        for(let listItem of list){
            if(listItem.id === id){
                listItem.done = check
            }
        }
        setList([...list]);
    };

    const handleDelete = (id) => {
        setList(list.filter((item) => item.id !== id));
    };

    return (
        <Container>
      <Row>
        <Col>
          <h1>Shoppinglist</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <AddItem
            onChange={(e) => setInput(e.target.value)}
            value={input}
            onAdd={(name) => {
              setList([...list, { id: list.length !== 0 ? Math.max(...list.map(item => item.id)) + 1 : 0, name: name, done: false }]);
            }}
          />
        </Col>
      </Row>
      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">Offen</Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <ul className="m-3">
              {list
                .filter((item) => !item.done)
                .map((item, idx) => (
                  <>
                    <li key={idx} className="d-flex justify-content-between">
                      {item.name}
                      <input
                        type="checkbox"
                        checked={item.done}
                        onChange={(e) =>
                          handleChange(item.id, e.target.checked)
                        }
                      />
                    </li>
                    <hr />
                  </>
                ))}
            </ul>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <Accordion className="mt-3">
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} variant="link" eventKey="0">Erledigt</Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <ul className="m-3">
              {list
                .filter((item) => item.done)
                .map((item, idx) => (
                  <>
                    <li key={idx} className="d-flex justify-content-between">
                      {item.name}
                      <div>
                      <Button variant="danger" className="mr-1" onClick={() => handleDelete(item.id)}>Löschen</Button>
                      <input
                        type="checkbox"
                        checked={item.done}
                        onChange={(e) =>
                          handleChange(item.id, e.target.checked)
                        }
                      />
                      </div>
                    </li>
                    <hr />
                  </>
                ))}
            </ul>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      
    </Container>
  );
}

function AddItem(props) {
    const handleSubmit = (e) => {
        e.preventDefault();
    props.onAdd(props.value);
};

return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <FormControl
          className="mb-3"
          placeholder="New Item"
          value={props.value}
          onChange={(e) => props.onChange(e)}
        ></FormControl>
      </Form>
    </Container>
  );
}


export default Shoppinglist;
