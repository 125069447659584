import "./App.css";
import { useState } from "react";
import { Container } from "react-bootstrap/";

function Home() {
  const [people, setPeople] = useState([
    {
      id: 0,
      firstName: "Peter",
      lastName: "Muster",
      email: "pm@muster.com",
      age: 45,
    },
    {
      id: 1,
      firstName: "Petra",
      lastName: "Muster",
      email: "pam@muster.com",
      age: 47,
    },
    {
      id: 2,
      firstName: "Paula",
      lastName: "Muster",
      email: "plm@muster.com",
      age: 12,
    },
  ]);

  const [counter, setCounter] = useState(0);

  const [fieldValue, setFieldValue] = useState("");

  const [seiteA, setSeiteA] = useState();

  const [seiteB, setSeiteB] = useState();

  // const [fläche, setFläche] = useState();

  // const [umfang, setUmfang] = useState();

  const [berechnung, setBerechnung] = useState();

  const [choose, setChoose] = useState();

  function deletePerson(personId) {
    setPeople(people.filter((person) => person.id !== personId));
  }

  function myClickHandler() {
    console.log("Button click");
  }

  function berechnen(a, b) {
    if (choose === "flaeche") {
      setBerechnung("Die Fläche ist: " + a * b);
    } else if (choose === "umfang") {
      setBerechnung("Der Umfang ist: " + a * 2 + b * 2);
    }
  }

  return (
    <Container>

    <div className="App">
      <h1>Hi</h1>
      <table>
        <tbody>
          <tr>
            <th>Firstname</th>
            <th>Lastname</th>
            <th>Email</th>
            <th>Age</th>
          </tr>
          {people
            .sort((a, b) => (a.age - b.age) * -1)
            .map((person) => (
              <tr>
                <td>{person.firstName}</td>
                <td>{person.lastName}</td>
                <td>{person.email}</td>
                <td>{person.age}</td>
                <td>
                  <button onClick={() => deletePerson(person.id)}>
                    Delete
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <button onClick={myClickHandler}>Click me</button>
      <button onClick={() => console.log("Ohne Function")}>Click me</button>
      <button onClick={() => setCounter(counter + 1)}>Plus</button>
      <p>{counter}</p>
      <button onClick={() => setCounter(counter - 1)}>Minus</button>

      <form
        onSubmit={(e) => {
          console.log("form proccessing" + fieldValue);
          e.preventDefault();
        }}
      >
        <input
          type="text"
          value={fieldValue}
          onChange={(e) => setFieldValue(e.target.value)}
        />
        <button type="submit">Submit</button>
      </form>
      <hr />
      <h1>Rechtecksberechnung</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          berechnen(seiteA, seiteB);
        }}
      >
        <p>
          Seite A{" "}
          <input
            type="number"
            value={seiteA}
            onChange={(e) => setSeiteA(e.target.value)}
          />
        </p>
        <p>
          Seite B{" "}
          <input
            type="number"
            value={seiteB}
            onChange={(e) => setSeiteB(e.target.value)}
          />
        </p>
        <div onChange={(e) => setChoose(e.target.value)}>
          <label>
            <input type="radio" name="rechnen" value="flaeche" /> Fläche
          </label>
          <br />
          <label>
            <input type="radio" name="rechnen" value="umfang" /> Umfang
          </label>
        </div>
        <input type="submit" value="Submit" />
      </form>
      <p>{berechnung}</p>
      <hr />
      </div>
    </Container>
  );
}

export default Home;
